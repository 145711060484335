.cadastre {
    position: relative;
}

.cadastre__title {
    color: var(--azul-primario);
    font-family: 'GT-America-Bold';
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center;
}

.cadastre__link {
    color: var(--azul-primario);
    text-decoration: underline;
}

.cadastre__card {
    position: relative;
    box-shadow: 0px 0px 6px #0000001A;
    border-radius: 0.8rem;
    min-height: 152px;
    position: relative;
    overflow: hidden;
    height: 100%;
}

.cadastre__card__back {
    background-position-x: 40%;
    border-radius:1rem;
    height: 150px;
    position: absolute;
    width: 100%;
    transform: rotate(180deg);
    top:0;
    background-size: cover;
    z-index: -100;
}

.cadastre__card__title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    /* font-family: $font-nova-bold; */
    color: #FFFFFF;
    padding-bottom: 18px;

}

.cadastre__card__title__text {
    font-size: 1.6rem;
    font-family: 'GT-America-Regular';
}

.cadastre__card__text {
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cadastre__card__text p {
    font-family: 'GT-America-Regular';
    text-align: center;
    padding: 0rem 3rem 2rem 3rem;
    line-height: 1.7rem;
    font-size: 1.5rem;
}

.cadastre__card__svg{
    width: 100%;
    height: 35px;
    position: absolute;
    background-position: 50%;
    border-top-right-radius: 1rem;
    z-index: -200;
    transform: scale(3.5);
    top:12px;
    left: -20px;
}

@media screen and (min-width: 768px) {
    .cadastre__card__svg {
        height: 29px;
    }

    .cadastre__card {
        height: 83%;
    }

    .cadastre__card__back {
        top:0;
    }

    .cadastre__card__title {
        padding-bottom: 0;
        height: 70px;
    }

    .cadastre__card__text p {
        padding: 1rem 3rem 1rem 3rem;
        font-size: 1.5rem;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .cadastre__container_card {
        width: 49.5% !important;
    }
}

@media screen and (min-width: 992px) {
    .cadastre__title {
        margin-left: 3rem;
    }

    .cadastre__container_card {
        width: 33% !important;
    }
}

@media screen and (min-width: 1200px) {
    .cadastre__title {
        margin-left: 0.5%;
    }
}