.footer{
    background-color: #F5F5F5;
    padding:1rem;
    padding-top:2rem;
    padding-bottom: 2rem;
}

.footer__logo {
    width: 235px;
    height: auto;
}

.footer__logo__div {
    justify-content: center;
}

.footer__div__text {
    padding: 0 !important;
}

.footer__text {
    color:#333333 ;
    padding-bottom: 1rem;
    font-family: 'GT-America-Regular';
    font-size: 1rem;
    cursor: pointer;
}

@media screen and (min-width: 992px) {
    .footer__logo {
        width: 270px;
        height: 30px;
    }

    .footer__logo__div {
        justify-content: end;
    }
}

@media screen and (min-width: 1200px) {
    .footer {
        padding-right: 2% !important;
        padding-left: 8% !important;
    }
}

@media screen and (min-width: 1400px) {
    .footer {
        padding-right: 10% !important;
        padding-left: 12% !important;
    }
}