.home__image-container {
    height: 100%;
    display: grid;
}

.home__image_desks {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.home__text {
    margin-bottom: 2rem;
}

.home__text-container {
    margin-left: 1rem;
    margin-right: 1rem;
}

.home__title h2 {
    color: var(--azul-primario);
    font-family: 'BNPP-Square-Bold';
    font-weight: bold;
    margin-top: 2rem;
    font-size: 5rem;
}

.home__text p {
    font-family: 'BNPP-Sans-Regular';
    font-weight: normal;
    font-size: 1.8rem;
    color: var(--azul-primario);
}

.bannerInicial {
    max-width: 1640px !important;
    width: 100%;
    margin: auto;
}

.container-cookie {
    position: relative;
}

.head__footer {
    background-color: var(--azul-primario);
    color: #FFFFFF;
    padding: 2.5rem;
    font-size: 1.3rem;
    text-align: center;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(4, max-content);
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    padding-right: 100px;
}

.separacion{
    height: 27px;
    width: 2px;
    background-color: #ffffff;
}

.head__footer p{
    width: 100%;
    margin-left: auto;
    text-align: end;
    color: #FFFFFF;
    font-family: 'GT-America-Regular';
}

@media screen and (max-width: 768px) {
    .home__image-container {
        height: 100%;
    }
    .banner__logo {
        padding-top: 3rem;
        padding-bottom: 2rem;
        position: inherit !important;
    }
}

@media screen and (max-width: 450px) {
    .row.bannerI {
        height: auto;
    }
}

@media screen and (min-width: 992px) {
    .col-image {
        padding-left: 0px !important;
    }

    .col-back {
        background-color: var(--primary-color);
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }

    .home__text-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* padding-bottom: 30rem; */
    }
}

@media screen and (min-width: 1200px) {
    .home__text-container {
        display: flex;
        padding-left: 5rem;
        height: 100%;
        flex-direction: column;
        justify-content: center;
    }

    .home__title h2 {
        font-family: 'BNPP-Square-Bold';
        width: 90%;
    }
}






.banner{
    /* margin-top:2rem; */
    
}

.banner__logo{
    width: 250px;
    padding-left: 2rem;
    padding-top: 1.5rem;
    margin-bottom: 1rem;
    position: absolute;
}

.banner__image{
    max-width: 100%;
    background-color: #91DDF8;
}

.banner__title{
    color:var(--azul-primario);
    font-size: 4rem;
    padding-left: 2rem;
    margin-top: 2rem;
}
.banner__text{
    padding-left: 2rem;   
    font-size: 1.5rem;
    color: var(--azul-primario);
    text-align: center;
}

.banner__button{
    background-color: var(--azul-primario);
    border:0;
    padding: 1rem 1.5rem;
    color:#fff;
    border-radius: 0.5rem;
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top:2rem;
    margin-bottom: 1.5rem;
}

.btn__head__homeout {
    width: 200px;
    padding: 0.5rem 2rem;
    height: 44px;
}

.banner__button:hover {
    background-color: var(--azul-oscuro);
    font-weight: bold;
}

.banner__footer{
    background-color: var(--azul-primario);
    color:#fff;
    margin-top:2rem;
    padding-top:1rem;
    padding-bottom:1rem;
    margin-right: 0;
}

.banner__footer__text{
    border-right: 1px solid #fff;
}

@media (min-width:768px){
    .banner__footer{
        margin-top:0;
    }
    .banner__footer{
        padding:2rem;
    }
    .banner__footer__text{
        display: flex;
        font-size: 1.2rem;
        font-weight: normal;
    }
}

.banner__text__center{
    display: flex;
    flex-direction: column;
    margin-top:5rem;
}

@media (max-width: 768px) {
    .banner__text__center{
        margin-top:0;
    }

    .banner__button {
        padding: 1.2rem 4.4rem;
    }
    .banner__footer {
        background-color: var(--azul-primario);
        color: #FFFFFF;
        justify-content: center;
    }

    .banner__footer__text {
        border-right: 1px solid #FFFFFF;
    }

    .banner__image {
        max-width: 100%;
    }

    .banner__title {
        text-align: center;
        padding-right: 2rem;
        font-size: 2.3rem;
    }
}

@media (min-width: 394px) and (max-width: 404px) {
    .banner__title {
        font-size: 2.2rem;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .home__title h2 {
        font-size: 4rem;
    }

    .btn__head__homeout {
        padding: 1.2rem 2.4rem;
    }
}

@media (min-width: 892px) and (max-width: 986px) {
    .banner__title {
        font-size: 3.4rem;
    }
}

@media (min-width: 1258px) and (max-width: 1392px) {
    .home__title h2 {
        font-size: 4.4rem;
    }
}