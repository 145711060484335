.sticky-top {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1020;
}
.header{
    box-shadow: 0px 0px 6px #808080;
}
.home__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8rem;
    font-size: 1.4rem;
    z-index: 1;
    background-color: #fff;
    
}

.home__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7rem;
}

.home__opciones {
    display: flex;
    gap: 3rem;
}

.separador__izquierdo {
    padding-left: 1.5rem;
}

.separador__izquierdo img{
    width: 215px;
}

.header__opcion {
    letter-spacing: 0.04rem;
    text-align: center;
    cursor: pointer;
}

.header__opcion:hover{
    color: #353D68;
    text-decoration: none;
    font-weight: 700;
}

.btn__cerrar-sesion {
    background-color: #ffffff;
    padding: 1rem 3rem;
    border-radius: 4px; 
    border: 1px solid var(--azul-primario);
    margin-top: 2rem;
    color: var(--azul-primario);
    font-size: 1.1rem;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
}

@media screen and (max-width: 1100px) {
    .home__header {
        padding: 0 0 0 2rem;
    }
}
  
@media screen and (max-width: 920px) {
    .home__header {
        padding: 0;
    }
}

@media screen and (max-width: 768px) {
    .home__header {
        padding: 0rem;
        justify-content: space-between;
        border-bottom: 1px solid var(--borde);
    }

    .home__logo {
        margin-left: 2rem;
    }
}

@media screen and (min-width: 1658px) {
    .home__opciones {
        margin-right: 6.4rem;
    }

    .home__header {
        margin: auto;
        max-width: 1658px;;
    }
}

@media screen and (min-width: 768px) {
    .home__opciones {
        margin-right: 8rem;
    }
}



/*========== HEADER MOVIL ==========*/
.menu__hambur{
    width: 25px;
    height: 20px;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
}
.menu__hambur div{
    height: 2px;
    width: 2.3rem;
    background-color: var(--azul-primario);
    border-radius: 1rem;
}

.menu__movil{
    position: fixed; 
    top:0;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    z-index: 1;
    right:-100%;
    transition: all .5s;
}
.menu__movil__show{
    right: 0%
}
.menu__movil__overlay{
    background-color: rgba(0,0,0,.5);
    height: 100%;
    width: 100%;
}
.menu__movil__content{
    position: relative;
    left: 20%;
    width: 80%;
    height: 100%;
    background-color: #fff;
    display: block;
    padding-top:2rem;
}

.menu__movil__hambur{
    width: 30px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    left:80%;
    font-size: 2rem;
    color:var(--azul-primario);
    cursor: pointer;
    margin-top:1rem
}

.menu__movil__hambur div{
    height: 2px;
    width: 100%;
    background-color: var(--azul-primario);
}


.menu__movil__text{
    text-transform: uppercase;
    text-align: center;  
    font-weight: bold;
    margin-top: 180px;
}
.menu__movil__text p{
    margin-top: 5rem;
}