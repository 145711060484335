.poliza__div {
    padding: 4rem 15rem;
}

.poliza__div>p {
    font-size: 1.8rem;
}

.modal__codigo-sms {
    padding: 2rem 4rem;
}

.modal__codigo-sms>p {
    font-size: 1.8rem;
}

.codigo-field {
    width: 40px;
    height: 55px;
    margin: 0 16px 0 0;
    border-radius: 10px;
    text-align: center;
    border: solid 1px #666666;
    background-color: #fff;
    font-size: 1.8rem;
}

.campos__codigo {
    padding: 2rem 2rem;
    text-align: center;
}

.sms__ayuda {
    font-size: 1.4rem;
}

.sms__ayuda>p {
    padding-top: 1rem;
    text-decoration: underline;
}

@media screen and (max-width: 648px) {
    .poliza__div {
        padding-right: 2rem !important;
        padding-left: 11rem !important;
    }
}
