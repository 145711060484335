.beneficios__title {
    color: var(--azul-primario);
    font-weight: bold;
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
    font-family: 'BNPP-Square-Bold';
    font-size: 1.2rem;
}

.beneficios__text-right {
    text-align: right !important;
}

.beneficios__card {
    box-shadow: 1px 1px 5px 1px #0000001A;
    border-radius: 0.6rem;
    min-height: 300px;
}

.beneficios__card__title,
.beneficios__card__text {
    padding:1rem;
    text-align: left;
}

.beneficios__card__title {
    display: flex;
    gap: 0.8rem;
    align-items: center;
}

.beneficios__card__title__head {
    color: var(--azul-primario);
    font-weight: bold;
    font-family: 'GT-America-Bold';
    font-size: 1.6rem;
    margin-bottom: 0;
}

.beneficios__card__text {
    padding-top:0;
}

.beneficios__card__text p {
    font-size: 1.25rem;
    line-height: 15px;
    font-weight: 580;
    font-family: 'GT-America-Medium';
}

.beneficios__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.beneficios__footer__text {
    font-size: 1.5rem;
    font-family: 'BNPP-Sans-Regular';
    margin-right: 1rem;
}

.beneficios__footer__button {
    border:none;
    background-color: var(--azul-primario);
    color: #FFFFFF;
    padding: 0.5rem 1.5rem;
    border-radius: 2rem;
    /* font-family: $font-nova-semibold; */
}

@media screen and (min-width: 768px) {
    .beneficios__title {
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 768px) {
    .beneficios__slide-btn {
        border: 0;
        background-color: #FFFFFF;
    }

    .carousel-item {
        transition: transform .6s ease-in-out !important;
    }

    .beneficios__footer__text {
        font-size: 1.5rem;
        margin-top: 4rem;
    }
}

@media screen and (min-width: 992px) {
    .beneficios__card__width {
        width: 25% !important;
    }

    .beneficios__card__title {
        margin-bottom: .7rem !important;
    }

    .beneficios__card__subtitle {
        font-size: .95rem;
        margin-bottom: 2rem !important;
    }

    .beneficios__card {
        height: 330px;
    }

    .beneficios__card__title__head {
        font-size: 1.6rem;
    }

    .beneficios__card__text p {
        font-size: 1.4rem;
    }

    .beneficios__footer {
        flex-direction: row;
        gap: 2rem;
        align-items: baseline;
    }

    .beneficios__footer__text {
        font-size: 1.7rem;
    }
}

@media screen and (min-width: 1200px) {
    .beneficios__card {
        height: 345;
    }
}

@media screen and (min-width: 1400px) {
    .beneficios__card {
        height: 370;
    }
}

