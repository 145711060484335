@font-face {
    font-family: 'Circular-Std';
    src: url('../../src/assets/fonts/CircularStd-Book[42734].otf') format('truetype');
    src: url('../../src/assets/fonts/CircularStd-Bold[42735].otf') format('truetype');
    src: url('../../src/assets/fonts/CircularStd-BookItalic[42732].otf') format('truetype');
    src: url('../../src/assets/fonts/CircularStd-Medium[42733].otf') format('truetype');
}

@font-face {
    font-family: 'Scotia-Regular';
    src: url('../../src/assets/fonts/Scotia_Rg.ttf') format('truetype');
}

@font-face {
    font-family: 'Scotia-Bold';
    src: url('../../src/assets/fonts/Scotia_Bd.ttf') format('truetype');
}

@font-face {
    font-family: 'Scotia-Headline';
    src: url('../../src/assets/fonts/Scotia_Headline.ttf');
}

@font-face {
    font-family: 'GT-America-Regular';
    src: url('../../src/assets/fonts/GT-America-Regular.otf');
}

@font-face {
    font-family: 'GT-America-Bold';
    src: url('../../src/assets/fonts/GT-America-Bold.otf');
}

@font-face {
    font-family: 'GT-America-Medium';
    src: url('../../src/assets/fonts/GT-America-Medium.otf');
}

@font-face {
    font-family: 'GT-America-Thin';
    src: url('../../src/assets/fonts/GT-America-Thin.otf');
}

@font-face {
    font-family: 'BNPP-Square-Bold';
    src: url('../../src/assets/fonts/BNPP Square Bold v3.ttf');
}

@font-face {
    font-family: 'BNPP-Sans-Regular';
    src: url('../../src/assets/fonts/BNPP Sans v3.otf');
}

@font-face {
    font-family: 'Helvetica-Regular';
    src: url('../../src/assets/fonts/Helvetica.ttf');
}

:root {
    --rojo: #EC111A;
    --rojo-oscuro: #E4032E;
    --gris-claro: #F7F7F7;
    --gris-oscuro: #707070;
    --azul-oscuro: #353D68;
    --azul-primario: #302F7C;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-size: 10px;
    font-family: "Scotia", "Open Sans", sans-serif;
}

body {
    font-family: "Scotia", "Open Sans", sans-serif;
}

p {
    margin-bottom: 0rem;
}

a {
    color: #000;
    text-decoration: none;
}

body .form-control {
    display: block;
    width: 100%;
    padding: 12px 24px;
    height: 45px;
    font-size: 11pt;
    font-weight: 400;
    margin: 10px 0px;
}

.landing__footer {
    background-color: #F7F7F7;
    color: #000000;
    padding: 1.5rem 1rem;
    font-size: 1rem;
}

@media (min-width: 992px) {
    .landing__footer {
        font-size: 1rem;
        padding: 3rem 15rem;
    }
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
    width: 4% !important;
}

@media screen and (min-width: 768px) {

    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
        position: absolute;
        top: 50% !important;
    }

    .alice-carousel__prev-btn {
        left: 0% !important;
    }

    .alice-carousel__next-btn {
        right: 0% !important;
    }
}

@media screen and (max-width: 768px) {

    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
        width: 14% !important;
    }

    .offcanvas.offcanvas-end.show {
        width: 85%;
    }
}

.logo__cardif {
    width: 15rem;
}

.logo__explora {
    width: 20rem;
}

@media screen and (max-width: 768px) {
    .logo__cardif {
        width: 12.5rem;
    }

    .logo__explora {
        width: 15rem;
    }
}

.row.bannerI {
    width: 100%;
    margin: auto;
}

.row.bannerH {
    width: 100%;
    margin: auto;
    height: 800px;
}

.sticky__btn__sidebar,
.sticky__btn__sidebar_up {
    position: fixed;
    bottom: 50%;
    text-align: right;
    right: 0;
    z-index: 1;
    -webkit-transform: rotate(-90deg) translateY(109%) translateX(-8%);
    -moz-transform: rotate(-90deg) translateY(109%) translateX(-8%);
    -o-transform: rotate(-90deg) translateY(109%) translateX(-8%);
    -ms-transform: rotate(-90deg) translateY(109%) translateX(-8%);
    transform: rotate(-90deg) translateY(109%) translateX(-8%);
}

.sticky__btn__sidebar_up {
    -webkit-transform: rotate(-90deg) translateY(-540%);
    -moz-transform: rotate(-90deg) translateY(-540%);
    -o-transform: rotate(-90deg) translateY(-540%);
    -ms-transform: rotate(-90deg) translateY(-540%);
    transform: rotate(-90deg) translateY(-540%);
    bottom: 48%;
}

.flecha__sticky__btn {
    position: relative;
    padding: 0 1rem;
    font-size: 1.5rem;
    bottom: 6px;
    left: 10px;
}

.btn__sidebar {
    background-color: var(--azul-primario);
    color: #ffffff;
    border: var(--azul-primario);
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    padding: 1.3rem 2.5rem;
    text-align: left;
    font-family: 'GT-America-Bold';
    font-size: 1.2rem;
}

.loadingGlobal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    color: #eee;
    padding: 2rem;
    width: 100vw;
    height: 100vh;
    font-size: 2rem;
    font-weight: 900;
    z-index: 9999;
}

.loadingCuadrado:before,
.loadingCuadrado:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 0.5em;
    height: 0.5em;
    border-radius: 0.25em;
    transform: translate(-50%, -50%);
}

.loadingCuadrado:before {
    animation: cuadradoBefore 2s infinite;
}

.loadingCuadrado:after {
    animation: cuadradoAfter 2s infinite;
}

.activa__underline {
    height: 2px;
    background-color: #000000;
    border-color: #000000;
    opacity: .5;
    margin-top: 3rem;
}

.body__login {
    padding: 1rem 2.3rem;
}

.body__login>h2,
.div__activar-cuenta>h2 {
    text-align: center;
    color: var(--azul-primario);
    padding-top: 5rem;
    padding-bottom: 2rem;
    font-family: 'GT-America-Bold';
    font-size: 2rem;
}

@media screen and (max-width: 768px) {

    .body__login>h2,
    .div__activar-cuenta>h2 {
        font-size: 2.7rem;
    }

    .recuperar__pass {
        font-size: 1.5rem;
        font-style: normal !important;
    }

    .body__login>p {
        font-size: 1.5rem !important;
        padding: 0 2rem;
    }
}

.body__login>p {
    font-size: 1.3rem;
    text-align: center;
    font-family: 'GT-America-Regular';
}

.modal__login__underline {
    height: 2px;
    background-color: var(--azul-primario);
    border-color: var(--azul-primario);
    opacity: .5;
    margin: 1rem 1.6rem;
}

.modal__login__footer {
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: underline;
}

.pass__icon {
    position: absolute;
    top: 50%;
    right: 0rem;
    transform: translateY(-50%);
    padding: 1.5rem;
    cursor: pointer;
    font-size: 1.6rem;
}

.pass__icon__register {
    position: absolute;
    top: 56.5%;
    right: 4rem;
    transform: translateY(-50%);
    padding: 1.5rem;
    cursor: pointer;
    font-size: 1.6rem;
}

.login__pass {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
}

.bannerInicial {
    max-width: 1640px !important;
    /* width: 80%; */
    margin: auto;
}

.btn__azul {
    background-color: var(--azul-primario);
    padding: 1.2rem 2rem;
    border-radius: 4px;
    border: 1px solid var(--azul-primario);
    margin-top: 2rem;
    color: #fff;
    font-size: 1.4rem;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
}

.btn__azul:hover {
    background-color: var(--azul-oscuro);
    border: 1px solid var(--azul-oscuro);
    font-weight: bold;
}

.div__botones {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    margin-left: 1rem;
    margin-right: 1rem;
}

@media (min-width: 768px) {
    .div__botones {
        flex-direction: row;
    }
}

@media (max-width: 768px) {
    .div__botones {
        margin-left: 3rem;
        margin-right: 3rem;
    }
}

.activa__checkbox {
    width: 1.25em;
    height: 1.25em;
    accent-color: var(--azul-primario);
    vertical-align: middle;
    filter: none !important;
}

@media screen and (max-width: 768px) {
    .activa__checkbox {
        width: 2em;
        height: 2em;
    }

    .caja__activa__label {
        text-align: left;
    }

    .activa__label {
        margin-left: 10px;
    }
}

.activa__label {
    vertical-align: middle;
    font-size: 1.4rem;
}

.coberturas__cards-in {
    /* display: flex; */
    justify-content: center;
    display: grid;
    gap: 2rem;
    padding-bottom: 0.5rem;
}

.recuperar__pass {
    text-align: right;
    font-style: italic;
    cursor: pointer;
}

@media (min-width: 768px) {
    .coberturas__cards-in {
        display: flex;
    }
}

.poliza__cards-in {
    display: flex;
    gap: 2rem;
}

.cob__cards-in,
.pol__cards-in {
    margin-top: 3rem;
    width: 100%;
    color: var(--texto);
}

.cob__card {
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.cob__cards h2,
.pol__cards h2 {
    font-weight: 700;
    font-size: 3.6rem;
    color: var(--texto-dark);
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.cob__card-img,
.pol__card-img {
    height: 12.6rem;
    width: 100% !important;
    position: relative;
}

.cob__card,
.pol__card {
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 2px 2px 2px 1px #4462f51c !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.5rem;
    max-width: 262px;
    min-height: 400px;
    height: 100%;
    /*margin: auto !important;*/
}

.cob__card-icoprincipal,
.pol__card-icoprincipal {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right center;
    border-radius: 0.5rem 0.5rem 0 0;
}

.cob__card-ico_container .cob__card-ico,
.pol__card-ico_container .pol__card-ico {
    /* icono container QA15/081 */
    position: relative;
    bottom: 0rem;
    width: 24px;
    height: 24px;
    left: 0rem;
    border-radius: 0rem;
    background: none;
    padding: 0px;
    margin: 0px;
    object-fit: contain;
    object-position: center;
}

.cob__card-ico_container,
.pol__card-ico_container {
    position: absolute;
    bottom: -2.5rem;
    left: 1rem;
    width: 45px;
    height: 45px;
    background-color: #fff;
    border-radius: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    box-shadow: 0px 4px 16px rgba(69, 91, 99, 0.14);
}

.cob__card-texto,
.pol__card-texto {
    padding: 2.5rem 2.5rem 0 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cob__card-title,
.pol__card-title {
    color: var(--azul-primario);
    font-family: 'GT-America-Bold';
    font-size: 1.8rem;
    font-weight: 700;
    font-weight: 700;
}

.cob__card-descripcion,
.pol__card-descripcion {
    font-size: 1.6rem;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
    font-family: 'GT-America-Regular';
}

.timer__codigo {
    text-align: center;
    font-family: 'GT-America-Regular';
    font-size: 1.3rem;
}

.reenviar__codigo {
    cursor: pointer;
}

.span__inicio__sesion {
    text-decoration: underline;
    cursor: pointer;
}

.body__cierre__session {
    text-align: center;
    padding: 2rem;
}

.body__cierre__session > h2 {
    color: var(--azul-oscuro);
    font-weight: bold;
}

.body__cierre__session > p {
    font-size: 1.5rem;
}

@media screen and (max-width: 648px) {
    .pol__card-icoprincipal {
        object-position: center;
    }
}

@media screen and (max-width: 768px) {
    .cob__card-descripcion {
        font-size: 1.5rem;
        padding: 0 2.6rem;
    }
}

.alice-carousel {
    text-align: center;
}

.cob__card-btn {
    display: flex;
    justify-content: right;
    margin-right: 2.5rem;
    margin-bottom: 2rem;
}

.pol__card-btn {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.link__conocemas {
    font-size: 14px;
    letter-spacing: -1px;
    text-decoration: none;
    cursor: pointer;
    margin-top: 2rem;
    text-align: right;
    color: var(--azul-primario);
}

.link__conocemas:hover {
    color: var(--azul-oscuro);
}

.item_activado {
    background-color: var(--azul-primario);
    transition: all .1s;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
}

.item_activado div {
    color: #ffffff !important;
}

.titulo__guias {
    margin: 0 26rem;
    text-align: left !important;
    padding-bottom: 0 !important;
}

.navSiniestro {
    color: #000;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
}

.navSiniestro:hover {
    color: #000;
}

.texto__siniestro>li {
    text-align: left;
    font-size: 1.8rem;
    padding-left: 0;
}

.texto__siniestro__div>p {
    text-align: left;
    font-size: 1.8rem;
    padding-left: 4rem;
}

.body__siniestro {
    margin: auto;
    padding-top: 4rem;
    padding-bottom: 4rem;
    width: auto;
}

.nav-perfil-links .nav-item .nav-link {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.nav-perfil-links .nav-link {
    padding: 1rem 4rem;
}

/* MODALES COBERTURAS */
.btnCierraModal {
    border: none;
    background-color: #ffffff00;
    position: absolute;
    right: 5px;
    top: 10px;
}

.close-modal {
    padding-left: 2px;
    height: 35px !important;
    right: -60px !important;
    display: flex !important;
    position: absolute !important;
    background: #fff !important;
    border-radius: 50% !important;
    width: 35px !important;
    font-size: 27px !important;
    color: #474747 !important;
    border: transparent !important;
    margin-bottom: 20px;
    outline: none;
}

.btnTermsClosePoli {
    margin-top: 44px;
    outline: none;
    margin-right: 18px;
}

.newCloseModalButton {
    position: absolute !important;
    top: -30px !important;
    right: -1px !important;
}

.accordion {
    --bs-accordion-active-color: #FFFFFF;
    --bs-accordion-active-bg: var(--azul-primario);
    --bs-accordion-btn-focus-box-shadow: #FFFFFF;
    --bs-accordion-btn-padding-y: 1.8rem;
    --bs-accordion-btn-icon-width: 2rem;
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 35 35'%3E%3Cg id='Grupo_8933' data-name='Grupo 8933' transform='translate(0 35) rotate(-90)'%3E%3Cpath id='Trazado_15019' data-name='Trazado 15019' d='M5.971,0,0,5.973l5.971,5.973' transform='translate(14.197 12.038)' fill='none' stroke='%23d12d37' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cg id='Elipse_192' data-name='Elipse 192' fill='none' stroke='%23d12d37' stroke-width='2'%3E%3Ccircle cx='17.5' cy='17.5' r='17.5' stroke='none'/%3E%3Ccircle cx='17.5' cy='17.5' r='16.5' fill='none'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    /*--bs-accordion-btn-icon: url('https://preproteccionfinanciera.vivetuseguro.com/static/media/Flecha_abajo.d450b3f3a9eb8aed05fe.svg');*/
    --bs-accordion-btn-active-icon: url('../../public/assets/img/homeout/Flecha_abajo_blanco.png')
}

.accordion-item:last-child {
    border-bottom: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color) !important;
}

.accordion-header {
    margin-bottom: 0 !important;
}

.accordion-button {
    font-size: 1.3rem;
}

.accordion-button:not(.collapsed) {
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 white;
}

.alice-carousel__next-btn {
    text-align: left;
}

.sidenav---icon-bar---u1f02 {
    background-color: var(--azul-primario) !important;
}

@media screen and (max-width: 764px) {
    .cob__card-texto {
        padding: 2.5rem 0.5rem 0 1rem;
    }

    .cob__card-title {
        font-size: 2.4rem;
        padding-right: 0rem;
    }

    .cob__card,
    .pol__card {
        max-width: 318px;
        min-height: 410px;
    }

    .cob__card-img,
    .pol__card-img {
        height: 17.6rem;
    }

    .modal__descripcion>h3 {
        font-size: 2.2rem;
        font-weight: bold;
        padding-top: 4rem !important;
    }

    .titulo__guias {
        margin: 0 1rem;
        text-align: center;
    }

    .texto__siniestro>li {
        font-size: 1.5rem;
    }

    .texto__siniestro__div>p {
        font-size: 1.5rem;
    }

    .rightClassButton {
        right: 15px !important;
    }
}

@keyframes cuadradoBefore {
    0% {
        width: 0.5em;
        box-shadow: 1em -0.5em rgba(248, 239, 237, 0.75),
            -1em 0.5em rgba(187, 239, 237, 0.75);
    }

    35% {
        width: 2.5em;
        box-shadow: 0 -0.5em rgba(248, 239, 237, 0.75),
            0 0.5em rgba(187, 239, 237, 0.75);
    }

    70% {
        width: 0.5em;
        box-shadow: -1em -0.5em rgba(248, 239, 237, 0.75),
            1em 0.5em rgba(187, 239, 237, 0.75);
    }

    100% {
        box-shadow: 1em -0.5em rgba(248, 239, 237, 0.75),
            -1em 0.5em rgba(187, 239, 237, 0.75);
    }
}

@keyframes cuadradoAfter {
    0% {
        height: 0.5em;
        box-shadow: 0.5em 1em rgba(32, 217, 216, 0.75),
            -0.5em -1em rgba(32, 175, 216, 0.75);
    }

    35% {
        height: 2.5em;
        box-shadow: 0.5em 0 rgba(32, 217, 216, 0.75),
            -0.5em 0 rgba(32, 175, 216, 0.75);
    }

    70% {
        height: 0.5em;
        box-shadow: 0.5em -1em rgba(32, 217, 216, 0.75),
            -0.5em 1em rgba(32, 175, 216, 0.75);
    }

    100% {
        box-shadow: 0.5em 1em rgba(32, 217, 216, 0.75),
            -0.5em -1em rgba(32, 175, 216, 0.75);
    }
}