.modal__groups {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    justify-items: center;
    margin: 0rem 5rem;
    width: auto;
}

.modal__descripcion {
    font-size: 1.8rem;
}

.modal__descripcion>h3 {
    color: var(--azul-primario);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-weight: bold;
}

.list__modal {
    padding-left: 0rem;
}

.list__modal li::before {
    content: "•";
    color: var(--azul-primario);
    font-weight: bold;
    display: inline-block;
    padding-right: 12px
}

.modal__activa-cuenta .modal-dialog{
    background-color: #fff;
    width: 85%;
    position: absolute;
    margin:0;
    right: 0;
    padding-bottom: 2rem;
    height: 100vh;
}

.modal__activa-cuenta__title {
    font-size: 2rem;
    font-weight: bold;
    padding: 0rem 4rem;
    color: var(--azul-primario);
    padding-top: 4rem;
    text-align: center;
}

.modal__activa-cuenta__body {
    font-size: 1.3rem;
    font-family: 'GT-America-Regular';
    padding: 0rem 4rem;
    text-align: center;
}

.form__modal {
    padding: 4rem 0rem;
}

.validador__container{
    margin-top: 1rem;
}

.auth__validador-resumen,
.validador__franjas{
    display:flex;
    gap: 1rem;
    justify-content:space-between;
}

.validador__franja{
    width: 6rem;
    height: .3rem;
    border-radius: 0.25rem;
    background-color: #4F5053;
}
.validador__franja.amarillo{
    /* background-color: var(--amarillo); */
    background-color: #F6D14E;
}
.validador__franja.rojo{
    /* background-color: var(--rojo); */
    background-color: #F6584E;
}
.validador__franja.verde{
    /* background-color: var(--verde); */
    background-color: #138468;
}

.auth__validador-detalle{
    font-weight:bold;
    font-size: 12px;
    font-weight:400;
    color: var(--texto-validador);
    margin-top: 1rem;
}
.validador__detalle-item{
    display: flex;
    gap: 1rem;
    margin-top: .5rem;
}
.validador__detalle-item i.verde{
    color: #00A693;
}
.validador__detalle-item i.plomo{
    color: #E9E9E9;
}

.footer__register > p {
    font-size: 1.5rem;
}

.link__login {
    font-family: 'GT-America-Bold';
    color: var(--azul-primario);
    cursor: pointer;
    text-decoration: underline;
}

.error__box {
    margin-top: 1.5rem;
    background-color: var(--rojo);
}

.texto__error > p {
    text-align: center;
    font-size: 1.8rem;
    color: #ffffff;
    padding: 0rem 1rem;
}

.modal__success {
    padding: 1rem;
    text-align: center;
}

.modal__success > h2 {
    color: var(--azul-primario);
    padding-bottom: 2rem;
    font-weight: bold;
}

.modal__success > p {
    font-size: 1.8rem;
}

.body__cookies,
.body__terminos,
.body__aviso {
    padding-left: 2rem;
    padding-top: 1rem;
    height: 75vh;
    overflow: auto;
}

.body__cookies::-webkit-scrollbar,
.body__terminos::-webkit-scrollbar,
.body__aviso::-webkit-scrollbar {
    -webkit-appearance: none;
}

.body__cookies p,
.body__terminos p,
.body__aviso p {
    font-family: 'Helvetica-Regular';
}

.modal__coursera {
    font-size: 1.3rem;
    padding: 2rem 8rem;
    text-align: center;
}

.modal__coursera > h3 {
    color: var(--azul-primario);
    font-family: 'GT-America-Bold';
    padding-bottom: 1.5rem;
}

.modal__coursera > p {
    font-family: 'GT-America-Regular';
    font-size: 1.3rem;
    padding: 0rem 1rem;
}

.div__botones__coursera {
    display: grid;
    grid-auto-flow: row;
    gap: 3rem;
    margin: auto;
}

.btn__coursera__go,
.btn__enviar__encuesta {
    background-color: var(--azul-primario);
    padding: 1rem 2rem;
    border-radius: 4px; 
    border: 1px solid var(--azul-primario);
    margin-top: 2rem;
    color: #fff;
    font-size: 1.1rem;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
}

.btn__coursera__go__disabled {
    pointer-events: none;
    background-color: #A7A7A7;
    padding: 1rem 2rem;
    border-radius: 4px; 
    border: 1px solid #A7A7A7;
    margin-top: 2rem;
    color: #fff;
    font-size: 1.1rem;
    cursor: no-drop;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
}

.btn__coursera__volver,
.btn__limpiar__encuesta {
    background-color: #ffffff;
    padding: 1rem 2rem;
    border-radius: 4px; 
    border: 1px solid var(--azul-primario);
    margin-top: 2rem;
    color: var(--azul-primario);
    font-size: 1.1rem;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
}

.encuesta__coursera {
    text-align: center;
    font-size: 1.6rem;
}

.encuesta__coursera > h1{
    font-family: 'GT-America-Bold';
    font-weight: 700;
    color: var(--azul-primario);
    padding-bottom: 3rem;
}

.encuesta__coursera > p {
    font-family: 'GT-America-Regular';
}

.preguntas__modal {
    margin-left: 3.1rem;
    margin-right: 3.1rem;
}

.card__btns__encuesta {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
}

.custom-radio input {
    padding: 1em 1em 0.8em;
    margin-right: 1rem;
}
  
.custom-radio label {
    font-size: 1.6rem;
    font-family: 'GT-America-Regular';
}

.custom-checkbox input{
    width: 10% !important;
    margin-right: 0rem;
}

.custom-checkbox label{
    width: 85% !important;
    margin-right: 0px !important;
    margin-bottom: 10px;
    font-family: 'GT-America-Regular';
}

.cuestionario__radio-button {
    width: 1.5em;
    height: 1.5em;
    accent-color: var(--azul-primario);
    vertical-align: text-bottom;
    margin-right: 15px;
}

.cuestionario__label {
    vertical-align: text-bottom;
}

.cuestionario__checkbox {
    width: 1.4em;
    height: 1.4em;
    accent-color: var(--azul-primario);
    vertical-align: text-bottom;
    filter: none !important;
}

.breakLine-mobile {
    display: none;
}

#profesion,
#ocupacion {
    font-size: 1.6rem;
    font-family: 'GT-America-Regular';
}

@media screen and (max-width: 768px) {
    .activacuenta__btn, .unirme__btn {
        padding: 1.5rem 0rem !important;
    }
}

@media screen and (max-width: 768px) {
    .modal__descripcion {
        font-size: 1.5rem;
    }

    .preguntas__modal {
        margin-left: 1.8rem;
        margin-right: 1.8rem;
    }

    .breakLine-mobile {
        display: block;
    }

    .btn__enviar__encuesta,
    .btn__limpiar__encuesta {
        width: 100%;
    }

    .modal__coursera {
        padding: 2rem 3rem;
    }

    .modal__coursera > h3 {
        font-size: 2.1rem;
    }

    .div__botones__coursera {
        gap: 0;
    }
}

@media screen and (min-width: 768px) {
    .div__botones__coursera {
        display: flex;
        justify-content: center;
    }
    
}


