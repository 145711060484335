.div__titulo__sidenav {
    padding-top: 3rem;
}

.div__titulo__sidenav > h2 {
    font-family: 'GT-America-Bold';
    color: var(--azul-primario);
}

.div__footer__sidenav {
    font-family: 'GT-America-Regular';
    position: absolute;
    bottom: 15px;
    text-align: center;
}

.div__footer__sidenav > p {
    cursor: pointer;
}

.linea__footer {
    height: 2px;
    background-color: var(--azul-primario);
    border-color: var(--azul-primario);
    opacity: .5;
    margin: 1rem 1.6rem;
}

.homein__sidebar__button_open {
    background: transparent;
    position: absolute;
    top: 4%;
    left: 0.2rem;
    transform: translateY(-50%);
    transition: left 0.55s ease;
}

.homein__sidebar__button_open .open {
    left: 14.2rem;
}

.homein__sidebar__button_open svg {
    cursor: pointer;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.homein__sidebar__button_open svg:hover {
    background: var(--azul-primario);
    fill: #ffffff;
}

.homein__sidebar__button_close {
    background: transparent;
    position: absolute;
    width: 1rem;
    top: 4%;
    right: 0%;
    transform: translateY(-50%);
    transition: left 0.55s ease;
    z-index: 2;
}

.homein__sidebar__button_close .close {
    left: 0.2rem;
}

.homein__sidebar__button_close svg {
    cursor: pointer;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.homein__sidebar__button_close svg:hover {
    background: var(--azul-primario);
    fill: #ffffff;
}

.sidenav__homein {
    padding-top: 8rem;
    padding-right: 2rem;
    padding-left: 2rem;
    background: #ffffff;
    border-right: 2px solid var(--azul-primario);
}

.sidenav__homein__expanded {
    width: 15% !important;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after {
    border-radius: 1rem;
}

.sidenav__item {
    padding-top: 8rem;
}

@media screen and (max-width: 648px) {
    .sidenav__homein__expanded {
        width: 60% !important;
    }

    .div__titulo__sidenav > h2 {
        font-size: 2.9rem;
    }

    .sidenav__item {
        padding-top: 4rem;
    }

    .sidenav__homein {
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .ico__sidenav__coursera {

    }
}