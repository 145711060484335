.contact {
    margin-bottom: 2rem;
}

.contact__container {
    box-shadow: 0px 0px 6px #0000001A;
    border-radius: 1rem;
    padding: 2rem 1rem 0rem 1rem;
    width: 97%;
}

.contact__container__card {
    display: flex;
    justify-content: center;
}

.contact__item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    justify-content: center;
}

.contact__item__text {
    font-size: 0.8rem;
    width: 160px;
    margin-left: 1rem;
    font-family: 'GT-America-Regular';
}

/* .contact__item__text__tel {
    font-family: $font-nova-medium;
} */

.contact__item__text__tel a,
.contact__item__text__tel a:hover {
    color: initial;
}

.contact__item__text__ayuda {
    font-family: 'GT-America-Bold';
    color: var(--azul-primario);
    font-size: 1.8rem;
}

.contact__item__text__small {
    font-size: 1.15rem;
}

.contact__item__border {
    position: relative;
}

.contact__item__border::after {
    content: "";
    width: 140px;
    height: 1px;
    background-color: #ccc;
    display: block;
    position: absolute;
    bottom: -15px;
}

@media screen and (min-width: 576px) {
    .contact__item__text {
        width: 200px;
    }
}

@media screen and (min-width: 992px) {
    .contact__container {
        padding-bottom: 2rem;
    }

    .contact__item {
        flex-wrap: nowrap;
        margin-bottom: 0;
        height: 100%;
        align-items: center;
    }

    .contact__item__normal {
        align-items: normal;
    }

    .contact__item__text {
        font-size: 0.9rem;
        width: auto;
    }

    .contact__item__border::after {
        width: 1px;
        height: 50px;
        right: 0;
        bottom: auto;
    }
}

@media screen and (min-width: 1400px) {
    .contact__item__border {
        margin-right: 1rem;
    }
}